import React, {ChangeEventHandler, memo, useCallback, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import {universalRequest} from "../Functions/ebay";
import SarchResultFinal from "../components/results/SarchResultFinal";
import './resultStyles.css';
import DefaultInput from "../components/input/DefaultInput";
import {defaultFilter} from "./Home/Home";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";



// STRINGS
export const getProductQueryUrl: string = "https://lesterwired.pythonanywhere.com/ebay-product-search-query/";
const baseColorSkeleton:string = "rgba(0,0,0,.4)";
const lineColorSkeleton: string = "rgba(0,0,0,.3)";

interface SearchResultScreenTypes {

}


const renderSkeletons = () => {
  let skeletons = [];
  for (let i = 1; i <= 25; i++) {
    skeletons.push(
      <SkeletonTheme baseColor={baseColorSkeleton} highlightColor={lineColorSkeleton}>
        <Skeleton className="thumbnail-image" count={1}/>
      </SkeletonTheme>
    );
  }
  // Das Array zurückgeben
  return skeletons;
}



const SearchResultScreen:React.FC<SearchResultScreenTypes> = () => {
  const [loading, setLoading] = useState(false);
  const [newQuery, setNewQuery] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");


  const location = useLocation();
  let dataReceived: {query: string} | undefined = location.state;



  const stateQueryErrorHandling = () => {

  };


  const searchErrorHandling = () => {

  };


  const getProductProcess = useCallback(async() => {
    setLoading(true);
    if (dataReceived && dataReceived.query && dataReceived.query.trim().length > 0){
      console.log("Search queries received:", dataReceived.query);
      await universalRequest(
        dataReceived.query,
        stateQueryErrorHandling,
        setNewQuery,
        defaultFilter
      );
      setLoading(false);
    } else if (searchInput.trim().length > 0 ) {
      console.log("Get Search Query Google input");
      await universalRequest(
        searchInput,
        searchErrorHandling,
        setNewQuery,
        defaultFilter
      );
      setLoading(false);
    }

  }, [dataReceived, newQuery, searchInput]);


  useEffect(() => {
    console.log("newQuery:", newQuery);
  }, [newQuery]);


  const mainContent = useCallback(() => {
    if (loading) {
      return renderSkeletons();
    }else if (newQuery) {
      console.log("NEW QUERY:", newQuery);
      const queryArray = Array.isArray(newQuery) ? newQuery : [newQuery];
      return queryArray.map((item, index) => (
        <SarchResultFinal key={index} item={item} index={index}/>
      ));
    }
    return <></>
  }, [loading]);


  useEffect(() => {
    getProductProcess()
      .then(() => {
        console.log("Products successfully set...");
        delete location.state.query;
      })
  }, []);


  const updateSearchInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    const text: string = event.target.value;
    setSearchInput(text);
  }


  return(
    <div className={"searchResultMainContainer"}>
        <div className={"searchContainer"}>
          <DefaultInput
            searchInput={searchInput}
            updateSearchInput={updateSearchInput}
            handleSubmit={
              () => {
                getProductProcess()
                  .then(() => {
                    console.log("Fetched Data...")
                  }
                )
              }
            }
          />
      </div>
      <div className={"grid2"}>
        {
          mainContent()
        }
      </div>
    </div>
  );
}

export default memo(SearchResultScreen)