import React, {memo, useCallback} from "react";
import "./resultStyles.css";
import { Rating } from '@smastrom/react-rating'

import '@smastrom/react-rating/style.css'

import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {calcRating} from "../../Functions/ebay";


interface SearchResultFinalTypes {
  item: any;
  index: number;
}


// STRINGS
const baseColorSkeleton: string = "#202020";
const lineColorSkeleton: string = "rgba(255,255,255,0.1)";


const SarchResultFinal: React.FC<SearchResultFinalTypes> = (
  {
    item
  }
) => {


  const shippingCostComponent = useCallback(() => {
    console.log("item.shippingOptions[0].shippingCost.value:", item.shippingOptions[0].shippingCost.value)
    if(item.shippingOptions[0].shippingCost.value == 0.00){
      const freeText:string = "Kostenlose \n Lieferung!"
      return(
        <div className={"d-flex flex-row justify-content-start align-items-start"}>
          <span className="new-price">
            {freeText}
          </span>
        </div>
      );
    }
    return(
      <div className={"d-flex flex-row justify-content-end valueContainer"}>
        <p className={"text-white shippingPriceText"}>Lieferkosten {"\u00A0"}</p>
        <span className="new-price">{item.shippingOptions[0].shippingCost.value}€</span>
      </div>
    );
  }, [item, item.shippingOptions[0].shippingCost.value]);


  const redirect = () => {
    window.open(item.itemAffiliateWebUrl, '_blank');
  }

  const searchResultImage = useCallback(() => {
    if(item.image && item.image.imageUrl) {
      return <img src={item.thumbnailImages[0].imageUrl} className="thumbnail-image" alt={"1123ewgr"}/>
    }
    return(
      <SkeletonTheme baseColor={baseColorSkeleton} highlightColor={lineColorSkeleton}>
        <Skeleton className="thumbnail-image" count={1} />
      </SkeletonTheme>
    )
  }, [item.image])

  return(
      <div className="productCard"  onClick={redirect}>
        <div className="image-container">
          {
            searchResultImage()
          }
        </div>
      <div className="titleContainer">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="dress-name text-white">{item.title}</h5>
        </div>
        <div className="d-flex flex-row mb-2 align-items-start valueContainer">
          <div className={"d-flex flex-column"}>
            <Rating
              readOnly
              style={{ maxWidth: 100, marginTop: 10, marginBottom: 5 }}
              value={
              calcRating(
                item.seller.feedbackPercentage
                )
              }
            />
            <span className="rating-number">{item.seller.feedbackPercentage}% positives Feedback</span>
          </div>
          <div className={"d-flex flex-column align-items-end justify-content-end valueContainer"}>
            <span className="price">{item.price.value}€</span>
            {
              shippingCostComponent()
            }
          </div>
        </div>
        <span className="rating-number">{item.seller.feedbackScore} Bewertungen*</span>
      </div>
    </div>
  );
}

export default memo(SarchResultFinal);


