import React from "react";
import {itemTypes} from "../../screens/Home/Home";

interface CategoryContainerTypes {
  item: itemTypes;
  handleClick: (() => void);
}

const CategoryContainer: React.FC<CategoryContainerTypes> = (
  {
    item,
    handleClick
  }
  ) => {
  return (
    <button onClick={handleClick} className="productContainer">
      <div className="swipe_box">
        <p>{item.title}</p>
      </div>
      <img src={item.src} alt="item.png" className={"categoryContainerImg"}/>
    </button>
  );
}

export default CategoryContainer;
