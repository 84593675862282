import React, {memo, useEffect} from "react";
import "./styles.css";
import { CiSearch } from "react-icons/ci";

interface DefaultInputTypes {
  searchInput: string;
  updateSearchInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
}

const DefaultInput: React.FC<DefaultInputTypes> = (
  {
    searchInput,
    updateSearchInput,
    handleSubmit
  }
) => {

  useEffect(() => {
    console.log("searchInput", searchInput)
  }, [searchInput]);
  return(
    <div className={"inputContainer flex justify-content-center align-items-center"}>
      <input
        className={"searchInput"}

        type="search"
        placeholder="Search..."
        aria-label="Search"
        value={searchInput}
        onChange={updateSearchInput}
      />
      <button className={"searchButton"} type={"submit"} onClick={handleSubmit} >
        <CiSearch/>
      </button>
    </div>
  );
}

export default memo(DefaultInput);