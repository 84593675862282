import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Envelope} from "react-bootstrap-icons";
import React, {memo, useCallback} from "react";
import "./nav.css";
import final_logo from "../../assets/images/final_logo.png";
import {WindowWidth} from "./WindowWidth";
import {useNavigate} from "react-router-dom";







const Header: React.FC = () => {

  const width = WindowWidth();
  const navigate = useNavigate();

  const headerText = useCallback(() => {
    if (width > 800) {
      return(
        <a href={"/"} className={"superHeader"}>
          OFFICIAL EBAY PARTNER
        </a>
      )
    }return <></>
  }, [width]);

  const goHome = () => {
    navigate("/")
  }

  return (
    <Navbar style={{height: 100}} className="bg-body-tertiary" data-bs-theme="dark">
      <Container fluid>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0"
               style={{ maxHeight: '100px' }}
               navbarScroll >
            <div className={"amz_partnerDiv"} >
              <img src={final_logo} alt="logo.png" className={"logo"} onClick={goHome}/>
              {
                headerText()
              }
            </div>
          </Nav>
          <div className={"d-flex p-0 gap-1 justify-content-center align-items-center m-auto"}>
            <div className={"d-flex p-0 gap-1 justify-content-start align-items-start"}>
              <Nav.Link href={"/faq"} id={"faqField"} style={{color: "rgba(255, 255, 255, .8)", marginRight: "5vmin"}}>
                FAQ
              </Nav.Link>
              <Nav.Link href={"/contact"} id={"contactField"} style={{color: "rgba(255, 255, 255, .8)"}}>
                Contact us
                <Envelope style={{margin: "auto 5px", marginRight: "5vmin"}}/>
              </Nav.Link>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default memo(Header);