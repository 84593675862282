import {memo, useCallback} from "react";
import React from "react";
import {Rating} from "@smastrom/react-rating";
import {calcRating} from "../../Functions/ebay";
import "./resultStyles.css";

interface ItemResultTypes {
  item: any;
  index: number;
}

const OfferResult: React.FC<ItemResultTypes> = (
  {
    item,
    index
  }
) => {

  const redirect = () => {
    window.open(item.itemAffiliateWebUrl, '_blank');
  }

  const swipeImages = useCallback(() => {
    if (item && item.additionalImages) {
      const images = item.additionalImages;

      return (
        <div className="image-container">
          {images.map((image: {imageUrl: string}, index: number) => (
            <img key={index} className="productImage" src={image.imageUrl} alt={`img-${index}`} />
          ))}
        </div>
      );
    } else {
      return <></>;
    }
  }, [item]);



  return(
    <section className={"productElement bg-dark"} style={{cursor: "pointer"}}>
      <div className={"productIndex"} onClick={redirect}>
        <h4>
          {index + 1}.
        </h4>
        <p>
          Rated
        </p>
      </div>
      <div className={"productImageContainer "} onClick={redirect}>
        <div className={"carouselContainer"} >
          <img key={index} loading="lazy" className="productImage" src={item.thumbnailImages[0].imageUrl} alt={`img-${index}`} />
        </div>
      </div>
      <div className={"informationContainer bg-dark"}>
        <div className={"productHeading"}>
          <p className={"headerText"}>
            {item.title}
          </p>
        </div>
        <div className={"ul"}>
          <Rating
            readOnly
            style={{ maxWidth: 100, marginTop: 10, marginBottom: 5 }}
            value={
              calcRating(
                item.seller.feedbackPercentage
              )
            }
          />
          <span className="rating-number">{item.seller.feedbackPercentage}% positives Feedback</span>
          <button className={"my-2"} onClick={redirect}>
            Watch Offer on eBay
          </button>
        </div>

      </div>
    </section>
  );
}
export default memo(OfferResult);










/*
 <div className="productcontainer">
        <div className="row">
          <div className="col-sm-3">
          </div>
          <div className="col-sm-9">
            <h2>Product Name</h2> <p>Description text...</p>
            <p><strong>Index:</strong> 1</p>
            <a href={"#"} className="btn btn-primary">Call to Action</a>
          </div>
        </div>
      </div>
  const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 500, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
*/












/*

 */

