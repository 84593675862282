import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css'
import ContactForm from "./screens/Contact";
import Offers from "./screens/OfferResultScreen";
import NotFound from "./screens/404";
import FAQ from "./screens/FAQ";
import Info from "./screens/Info";
import Home from "./screens/Home/Home";
import Footer from "./components/navigation/Footer";
import React from "react";
import SearchResultScreen from "./screens/SearchResultScreen";
import Header from "./components/navigation/Header";

const App :React.FC= () => {

  return(
    <Router>
      <div className={"headerContainer"}>
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<ContactForm />} />
        <Route path="imprint" element={<Info />} />
        <Route path="offers" element={<Offers />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="*" element={<NotFound />} />
        <Route path="categories" element={<Offers />} />
        <Route path="search" element={<SearchResultScreen />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;


/*
  const [dark_mode_bool, set_dark_mode_bool] = useState(false);

<div className={"dark_light_container"}>
        <div className="form-check form-switch">
          <input onClick={dark_mode_function} className="form-check-input dark_mode_button" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
        </div>
      </div>


  const dark_mode_function = () => {
    const reminder = document.querySelector(".reminderContainer");
    const dark_icon = document.getElementById("offerHeader");
    const elements = document.querySelectorAll(
      ".infoSectionMain, .reminderContainer, .dark_light_container")

    elements.forEach((element: HTMLElement) => {
      element.style.backgroundColor = dark_mode_bool ?   "#232121" : "#e8e5e5";
    });
    reminder.style.color = dark_mode_bool ? "#e8e5e5" : "rgba(0, 0, 0, .8)";
    dark_icon.style.color = dark_mode_bool ? "rgba(0, 0, 0, .8)" : "#e8e5e5";

    set_dark_mode_bool(!dark_mode_bool);
    const rootRouter = document.getElementById("root");
    rootRouter.style.backgroundColor = dark_mode_bool ? "#e8e5e5" : "#212529";
  }
 */