import React, {memo} from "react";
import {WindowWidth} from "../navigation/WindowWidth";

import infoFirst from "../../assets/images/infoFirst.png"
import infoSecond from "../../assets/images/infoSecond.png"
import infoThird from "../../assets/images/infoThird.png"

const InfoSectionHome: React.FC = () => {

  const fistLastContainerClass: string = "flex justify-content-start align-items-start";
  const middleContainerClass: string = "flex justify-content-start align-items-end";

  const imageContainer = (row: string, col: string, image: any) => {
    const width:number = WindowWidth();
    console.log("width:", width);
    if (width > 800) {
      return(
        <div style={{gridRow: row, gridColumn: col}} className={"infoSectionMainImgSection"}>
          <div id={"imgROW2"} className={"infoSectionImgContainer"}>
            <img className={"infoSectionIMG"} src={image} alt="previewImage.png"/>
          </div>
        </div>
      )
    }
  }


  return(
    <div className={"infoSectionMain"}>
      {
        imageContainer(
          "1",
          "1",
          infoFirst
        )
      }

      <div  className={"infoSectionMainTextSection infoSectionTextDiv1"}>
        <div id={"textROW1"} className={fistLastContainerClass}>
          <h4>Entdecken Sie die Welt der Top 10 Angebote, . . . </h4>
          <p className={"infoSectionMainText"}>
            Mit unserem benutzerfreundlichen Vergleichsservice helfen wir Ihnen das perfekte Produkt aus einer
            Vielzahl von Kategorien zu finden. Wir machen den Preisvergleich zum Kinderspiel und bringen die
            besten Angebote direkt zu Ihnen. Ob Sie auf der Suche nach Elektronik, Baumarktartikeln, Garten- und
            Hausprodukten oder Hifi-Entertainment sind - mit unseren Top-Angeboten im Vergleich ist es einfacher
            denn je, genau das zu finden, was Sie suchen. Machen Sie sich bereit, die besten Angebote zu entdecken
            und das Online-Shopping-Erlebnis zu genießen.
          </p>
          <a href="#">Explore offers</a>
        </div>
      </div>

      <div className={"infoSectionMainTextSection infoSectionTextDiv2"}>
        <div id={"textROW2"} className={middleContainerClass}>
          <h4 >. . . maßgeschneidert auf Ihre Bedürfnisse . . . </h4>
          <p className={"infoSectionMainText"}>
            Wir erstellen für jedes Produkt eine Rangliste basierend auf verschiedenen Faktoren wie
            Kundenbewertungen und Verkaufsrängen. Dies erleichtert es Ihnen, sich in der umfangreichen
            Produktlandschaft zurechtzufinden und die besten Angebote zu entdecken.
            Unsere Mission ist es, Ihnen dabei zu helfen, informierte Entscheidungen zu treffen und das Beste aus
            Ihrem Online-Shopping-Erlebnis herauszuholen. Entdecken Sie die Möglichkeiten und lassen Sie sich von
            den zahlreichen Optionen inspirieren.
          </p>
        </div>
      </div>

      {
        imageContainer(
          "2",
          "3",
          infoSecond
        )
      }

      {
        imageContainer(
          "3",
          "1",
          infoThird
        )
      }

      <div className={"infoSectionMainTextSection infoSectionTextDiv3"}>
        <div id={"imgROW3"} className={fistLastContainerClass}>
          <h4>... einfach und unkompliziert!</h4>
          <p className={"infoSectionMainText"}>
            Durch einen produktvergleich könne Sie nicht nur erheblich sparen, sondern auch ein qualitativ
            hochwertigeres Produkt finden, als Sie ursprünglich geplant hatten.
            Unsere Plattform dient als Ihr persönlicher Navigator auf der Suche nach dem passenden Produkt.
            Unser Motto lautet: Vergleichen - einfach und schnell.
          </p>
        </div>
      </div>
    </div>
  )
}


export default memo(InfoSectionHome);