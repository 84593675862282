
import {useLocation} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import ReactLoading from "react-loading";
import {itemRequest} from "../Functions/ebay";
import ItemResult from "../components/results/OfferResult";
import {getProductQueryUrl} from "./SearchResultScreen";
import {defaultFilter} from "./Home/Home";



function Offers() {
  const [loading, setLoading] = useState(false);
  const [newQuery, setNewQuery] = useState([]);

  const location = useLocation();
  const dataReceived = location.state;


  const getProductItems = async () => {
    setLoading(true);
    try {
      if (!dataReceived) {
        console.log("No data received...")
      }
      else if (dataReceived.query) {
        console.log("Query received: " + dataReceived.query);
        return await itemRequest(getProductQueryUrl, dataReceived.query, 10, defaultFilter);
      }
    }catch (e) {
      console.log("Error while request occurred:", e);
    }finally{
      setLoading(false);
    }
  }

  const getItemComponents = async () => {
    const response = await getProductItems();
    if (response && response.status === 200) {
      try {
        const parsedResponse = await response.json();
        console.log("Request successfully...");
        console.log("Response Items:", parsedResponse.items);
        setNewQuery(parsedResponse.items);
      } catch (error) {
        console.error("Error parsing response:", error);
      }
    } else {
      console.error("Server response was not ok");
    }};


  useEffect(() => {
    getItemComponents()
      .then(() => {
        console.log("Fetched Products...")
      })
  }, []);


  const resultContainer = useCallback(() => {
    console.log("NEW QUERY:", newQuery);
    const queryArray = Array.isArray(newQuery) ? newQuery : [newQuery];

    if (!loading && queryArray.length > 0) {
      console.log("QUERY ARRAY")
      return queryArray.map((item, index) => (
        <ItemResult key={index} item={item} index={index}/>
      ));
    } else if (loading) {
      return <ReactLoading type={"spin"} color={"rgb(0, 0, 255)"} height={100} width={50} />;
    }}, [newQuery, loading]);


  return (
    <>
      <div className={"searchResultMain"}>
        <div className={"searchResultHeader"}>
          <h3>
            Unsere Top Empfehlungen für {dataReceived.query}!
          </h3>
        </div>
        <div className={"searchResultContent"}>
          {
            resultContainer()
          }
        </div>
      </div>
      <div className={"infoTextContentPage"}>
        <p>
          Wichtige Informationen:
          Wir erhalten ggf. eine Provision, falls Sie einen Kauf über die Links auf unserer Seite tätigen.
          Dies hat keine Auswirkungen auf die Platzierung der Produkte auf unserer Seite.
          Unsere Seite arbeitet mit dem Ebay Partner-/Affiliateprogramm zusammen. Ebay,
          das Ebay Logo ist eingetragenes Warenzeichen von Ebay, Inc. oder dessen Partnern.

          Bitte beachten Sie zudem folgende wichtige Hinweise:
          1. Der angezeigte Preis könnte seit der letzten Aktualisierung gestiegen sein.
          2. Der Verkauf erfolgt zu dem Preis, den der Verkäufer zum Zeitpunkt des Kaufs auf seiner Website für das
          Produkt angibt.


          Fussnoten:

          * der ggf. verwendete Ausdruck Beste u.ä. bezieht sich nur auf unsere ggf. subjektive persönliche Meinung.
          ** "Kostenfreie Lieferung" bedeutet, dass dies unter gegebenen Voraussetzungen zum Zeitpunkt des Datenabrufs
          vom entsprechenden Anbieter möglich ist, aber nicht garantiert werden kann. Eine Angabe in Echtzeit ist
          technisch nicht möglich. Die Versandkosten können unter bestimmten Umständen (abweichender Anbieter,
          Lieferort etc.) unterschiedlich sein. Es gelten die letztendlich im weiteren Bestellprozess angezeigten
          Versandkosten.
        </p>
      </div>
    </>
  );

}
export default Offers;