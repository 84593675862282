import {getProductQueryUrl} from "../screens/SearchResultScreen";
import {Dispatch, SetStateAction} from "react";


export const itemRequest = async (url:string, queries: string, value: number, filters: string) => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      queries: queries,
      value: value,
      filters: filters,
    })
  });
}






export const universalRequest = async (
  data: string,
  errorHandling: () => void | undefined,
  setNewQuery: Dispatch<SetStateAction<any>>,
  filters: string,
) => {
  try{
    const response = await itemRequest(getProductQueryUrl, data, 25, filters);
    const parsedResponse = await response.json();
    console.log("search Request successfully...");
    console.log("search Response Items:", parsedResponse.items);
    setNewQuery(parsedResponse.items);
  }catch(e:unknown){
    if (e instanceof Error) {
      console.log("Error occurred while fetching the data:", e);
      if (errorHandling){
        errorHandling()
      }
    }
  }
}

export const calcRating = (feedbackPercentage:number) => {
  const starRating:number = feedbackPercentage/100 * 5;
  console.log("Star Rating:", starRating);
  return starRating;
};



/* EXAMPLE itemSumary#

As an eBay Partner Network affiliate or Buy API Partner,
you can drive traffic to eBay via the traditional affiliate model,
or let your customers browse eBay items and purchase these items without
leaving your site—also known as contextual commerce.


{
            "itemId": "v1|3**********0|0",
            "title": "FIFA WORLD CUP FULL SIZE SOCCER BALL INTERNATIONAL COUNTRY FLAGS OFFICIAL SIZE 5",
            "image": {
                "imageUrl": "https://i.ebayimg.com/thumbs/images/g/0**********p/s-l225.jpg"
            },
            "price": {
                "value": "8.95",
                "currency": "USD"
            },
            "itemHref": "https://api.ebay.com/buy/browse/v1/item/v1|3**********0|0",
            "seller": {
                "username": "u***s",
                "feedbackPercentage": "99.0",
                "feedbackScore": 378908
            },
            "condition": "New",
            "conditionId": "1000",
            "thumbnailImages": [
                {
                    "imageUrl": "https://i.ebayimg.com/00/s/N**********=/z/0iIAAOSwEzxYUFbp/$_0.JPG?set_id=8**********F"
                }
            ],
            "shippingOptions": [
                {
                    "shippingCostType": "FIXED",
                    "shippingCost": {
                        "value": "0.00",
                        "currency": "USD"
                    }
                }
            ],
            "buyingOptions": [
                "FIXED_PRICE"
            ],
            "currentBidPrice": {
                "value": "8.95",
                "currency": "USD"
            },
            "epid": "1134280568",
            "itemWebUrl": "http://www.ebay.com/itm/FIFA-WORLD-CUP-FULL-SIZE-SOCCER-BALL-INTERNATIONAL-COUNTRY-FLAGS-OFFICIAL-SIZE-5-/3**********0?hash=item4**********p",
            "itemLocation": {
                "postalCode": "6****",
                "country": "US"
            },
            "leafCategoryIds": [
                "20863"
            ],
            "categories": [
                {
                    "categoryId": "20863",
                    "categoryName": "Soccer Balls"
                },
                {
                    "categoryId": "888",
                    "categoryName": "Sporting Goods"
                },
                {
                    "categoryId": "20862",
                    "categoryName": "Soccer Equipment"
                },
                {
                    "categoryId": "159049",
                    "categoryName": "Team Sports"
                }
            ],
            "additionalImages": [
                {
                    "imageUrl": "https://galleryplus.ebayimg.com/ws/web/3**********0_2_0_1/225x225.jpg"
                }
            ],
            "priorityListing": true,
            "adultOnly": false,
            "availableCoupons": false,
            "legacyItemId": "3**********0"
        },


 */