import axios from "axios";

import bild from "../../assets/images/bild.png";
import {ChangeEventHandler, memo, useCallback, useEffect, useState} from "react";
import '../../App.css'
import {useNavigate} from "react-router-dom";
import ProductContainer from "../../components/container/CategoryContainer";
import React from "react";
import DefaultInput from "../../components/input/DefaultInput";
import InfoSectionHome from "../../components/container/InfoSectionHome";

// IMAGES
import android from "../../assets/images/category/android.png";
import beauty from "../../assets/images/category/beauty.jpg";
import gaming from "../../assets/images/category/gaming.png";
import garden from "../../assets/images/category/garden.png";
import kitchen from "../../assets/images/category/kitchen.png";
import techTools from "../../assets/images/category/techTools.png";

export interface itemTypes {
  title: string;
  src: string;
}

let items: itemTypes[] = [
  {
    title: "Smartphones",
    src: android
  },
  {
    title:  "Garten",
    src: garden
  },
  {
    title:  "Küchenzubehör",
    src: kitchen
  },
  {
    title:  "Gaming",
    src: gaming
  },
  {
    title:  "Beauty & Make-up",
    src: beauty
  },
  {
    title: "Technikzubehör",
    src: techTools
  }

]

// STRINGS
export const defaultFilter:string = "filter=buyingOptions:{FIXED_PRICE|BEST_OFFER},conditions:{NEW},deliveryCountry:DE,itemLocationCountry:DE,maxDeliveryCost:0,priceCurrency:EUR";


const Home: React.FC = () => {
  const [searchInput, setSearchInput] = useState<string>("");
  const navigate = useNavigate();
/*
  const  getCategoryIds = () => {
    setLoading(true);
    await universalRequest(
      dataReceived.query,
      stateQueryErrorHandling,
      setNewQuery
    );
    setLoading(false):
  }
*/



  const updateSearchInput: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    const text: string = event.target.value;
    setSearchInput(text);
  }, [searchInput]);


  const handleSubmit = async (e: any) =>  {
    e.preventDefault();
    const { search } = e.target.elements
    let searchData:{first_name: string} = {
      first_name: search.value
    }
    try {
      const response = await axios.post("http://localhost:5173/TODOAPIENDPOINT", searchData)
      window.location.href= response.data.successUrl;
    } catch (error) {
      console.log(error);
    }
  };


  const handleButtonlick = useCallback((navigation: string, dataToPass: { query: string }) => {
    return () => {
      console.log("Category clicked...");
      navigate(navigation, { state: dataToPass });
    };
  }, [navigate]);


  const renderItem = () => {
    return items.map((single_item, index) => (
      <ProductContainer
        handleClick={handleButtonlick(
          '/categories',
          {
            query: single_item.title,
          }
        )}
        key={index}
        item={single_item}
      />
    ));
  };


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry:IntersectionObserverEntry) => {
        const element: HTMLElement = entry.target as HTMLElement;
        if (entry.isIntersecting) {
          element.style.opacity = '1';
          element.style.filter = 'blur(0)';
          element.style.transform = 'translateX(0)';
        }
      });
    });
    const hiddenElements = document.querySelectorAll('.swipe_box, #textROW1, #textROW2, #textROW3, #imgROW1, #imgROW2, #imgROW3');
    hiddenElements.forEach((el) => observer.observe(el));
  }, []);


  const handleButtonClick = useCallback(() => {
    return () => {
      console.log("Search header Btn clicked...");

      navigate("/search", {state: {
          query: searchInput
        }
      });
      setSearchInput("");
    };
  }, [navigate, searchInput]);



  return(
      <div className={"mainContainer"}>
        <div className={"mainContainerGRID1"}>
            <img src={bild} className={"mainHomeIMG"} alt="bild.png"/>
          <div className={""}>
            <DefaultInput
              searchInput={searchInput}
              updateSearchInput={updateSearchInput}
              handleSubmit={
                handleButtonlick(
                  "/search",
                  {
                    query: searchInput
                  }
                )
              }
            />
          </div>
        </div>
        <div className={"mainContainerGRID2"}>
          <div className={"mainContainerGRID2GRID1"}>
            <h2 id={"offerHeader"} style={{textAlign: "center"}}>
              Unsere Empfehlungen und Angebote für Februar 2024!
            </h2>
          </div>
          <div className={"mainContainerGRID2GRID2"}>
            {renderItem()}
          </div>
        </div>

        <InfoSectionHome />

        <div className={"reminderContainer  flex justify-content-center align-items-center"}>
          <div className={"reminderInnerContainer mx-3 flex justify-content-center align-items-center"}>
            <h5>Important Information:</h5>
            <p>
              Please be advised that we may receive a commission if you make a purchase through the links on our site.
              However, this does not affect the placement of products on our site. We are proud to work with the eBayEU
              affiliate/affiliate program. Amazon, eBayPrime and the eBay logo are registered
              trademarks of eBay, Inc. and its affiliates.
            </p>
            <h5>Please also note the following important information:</h5>

            <h5>Footnotes:</h5>
            *The term "Best" and similar expressions refer only to our possibly subjective personal opinion. <br/>
            ** "Free delivery" means that this is possible under certain conditions at the time of data retrieval from
            the corresponding provider, but cannot be guaranteed. <br/>
            An indication in real time is technically not possible. <br/>
            Shipping costs may vary under certain circumstances (different provider, delivery location, etc.). <br/>
            The shipping costs ultimately displayed in the further ordering process apply.
          </div>
        </div>
      </div>

  );
}
export default memo(Home);
