import React, {memo} from "react";
import "./buttonStyles.css";

const ContactSubmitButton: React.FC = () => {
  return(
    <button className="flex submitButton justify-content-center align-items-center" type="submit">
      Send
    </button>
  );
}

export default memo(ContactSubmitButton);